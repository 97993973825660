import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Package, Supply, SupplyType } from "../../../types";
import { RootState } from "../../../redux/store";
import { addNewSupply, addNewSupplyType } from '../../../redux/slices/ProductSlice';
import { addNewPackage } from "../../../redux/slices/PackageSlice";

export const WebsocketsUtils = () => {
    const domain = "wss://portal.demo.api.elvinci.de/ws/echo/";
    const [socket, setSocket] = useState<any>(new WebSocket(domain));
    const dispatch = useDispatch();
    const productSupplies: Supply[] = useSelector((state: RootState) => state.productSlice.productSupplies);
    const supplyTypes: SupplyType[] = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const allPackages: Package[] = useSelector((state: RootState) => state.packageSlice.packages);

    const startListeners = () => {
        socket.onmessage = function(event: any) {
            const data = JSON.parse(event.data);
            console.log('Data received: ', data);
            if(data?.message == "supply_created") {
                const updated_supply = productSupplies.filter((item) => {
                    return item.id == data?.instance?.id
                })
                if (updated_supply?.length === 0) {
                    dispatch(addNewSupply(data?.instance))
                }
            }

            else if(data?.message == "package_created") {
                const updated_package = allPackages.filter((item) => {
                    return item.id == data?.instance?.id
                })
                if (updated_package?.length === 0) {
                    dispatch(addNewPackage(data?.instance))
                }
            }
            else if(data?.message == "supply_type_created") {
                const updated_supplytype = supplyTypes.filter((item) => {
                    return item.id == data?.instance?.id
                })
                if (updated_supplytype?.length === 0) {
                    dispatch(addNewSupplyType(data?.instance))
                }
            }
            // update your frontend state here
          };
        
        socket.onopen = function(event: any) {
            socket.send(JSON.stringify({message: 'Hello Server!'}));
        };
    }

    return {startListeners}
}
