

import { useDispatch } from 'react-redux';
import { setCart, setPackages, setSelectedPallets, setSelectedProducts, setSelectedSetArticles, setSetArticles, setAllPackagesCount, setPackageProducts, setPendingPackages } from '../../../redux/slices/PackageSlice';
import { 
  setWarehouseSetArticles, setWarehouseSelectedProducts, setWarehouseSelectedSetArticles, setWarehouseCart, setWarehousePackageProducts,
  setWarehousePackageTotalPrice, setWarehouseSelectedPallets, setWarehousePallets, setWarehousePackageDiscount
} from '../../../redux/slices/WarehousePackageSlice';

import { getPackages, getPackagesCount, getPendingPackages } from '../../../services/PackageService';
import { CatalogItem, CustomerDetails, ProductItem } from '../../../types';
import { setOrders, setAllOrdersCount } from '../../../redux/slices/OrderSlice';
import { getOrders, getOrdersCount } from '../../../services/OrderService';
import { setCountries, setCustomers, setPermissionGroups, setPermissions } from '../../../redux/slices/UsersSlice';
import { getAllCountries, getAllGroups, getCustomers } from '../../../services/CustomerService';
import { filterPallets, getActivePalletsCount, getPallets } from '../../../services/PalletService';
import { downloadImages, getActiveProductsCount, getManualProductPrices, getProductArticles,
  getProductBrands, getProductCategories, getProductSubCategories, getProductSupplies, getProducts,
  getProductsCategoryQuestions, getSuppliers, getSupplyTypes, getUsage, 
} from '../../../services/ProductService';
import {
  setManualProductPrices, setProductArticles, setProductBrands, setProductCategories, setProductSubCategories, setProductSupplies,
  setSuppliers, setSupplyTypes, setUsage, setProducts, setProductsCount, setPalletProducts, setActivePalletsCount, setActiveProductsCount,
  setProductCategoryQuestions
} from '../../../redux/slices/ProductSlice';

import { changeAuthPassword, getAllPermissions, logoutUser } from '../../../services/AuthService';
import { setLoggedinUser, setIsAuthenticated } from '../../../redux/slices/AuthSlice';
import { AnalyticsUtils } from './SalesAnalyticsUtils';
import { baseColumnStyle, columnStyle, FONT_18, PAGE_NUMBER } from '../../../constants';
import { getProductPDFURL, getOrderPDFURL, getSupplyPDFURL } from '../DownloadPDF';
import { PDFGeneratedSuccessToast, PDFGeneratedErrorToast } from '../create-package-components/ToastsComponent';
import { useState } from 'react'
import { RootState } from '../../../redux/store';
import { setSelectedTab } from '../../../redux/slices/CustomerTotalOrdersSlice';
import { setCatalogArticles, setInCompleteCatalog } from '../../../redux/slices/CatalogSlice';
import { filterCatalogByModel, getCatalogById } from '../../../services/CatalogService';
import { useTranslation } from 'react-i18next';

export const Utils = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getAllAnalyticsData } = AnalyticsUtils();
  const [toast, addToast] = useState<any>(null);
  const [toastShown, setToastShown] = useState<boolean>(false);
  const PalletProductReducedColumns: any = [

    {
      key: 'article',
      label: t('adminDashboard.Products.article'),
      _style: baseColumnStyle,

    },
    {
      key: 'current_online_price',
      label: t('adminDashboard.Products.online_price'),
      _style: columnStyle,
    },
    {
      key: 'total_current_online_price',
      label: t('adminDashboard.Pallets.total_online_price'),
      _style: columnStyle,
    },
    {
      key: 'selling_price',
      label: t('adminDashboard.Products.selling_price'),
      _style: columnStyle,
    },
    {
      key: 'total_selling_price',
      label: t('adminDashboard.Pallets.total_selling_price'),
      _style: columnStyle,
    },
    {
      key: 'quantity',
      label: t('adminDashboard.CommonWords.quantity'),
      _style: columnStyle,
    },
    {
      key: 'brand',
      label: t('adminDashboard.Products.brand'),
      _style: columnStyle,
    },

    {
      key: 'sub_category',
      label: t('adminDashboard.Products.category'),
      _style: columnStyle,

    },
  ];
  const customerOrderColumns: any = [
    {
      key: 'id',
      label: 'Id',
      filter: false,
      sorter: false,
    },
    {
      key: 'status',
      label: t('adminDashboard.CommonWords.status'),
      filter: false,
      sorter: false,
    },
    {
      key: 'package',
      label: `${t('adminDashboard.Packages.package')} Id`,
      filter: false,
      sorter: false,
    },
    {
      key: 'actions',
      label: '',
      filter: false,
      sorter: false,
    },
  ];
  const customerOrderDetailsColumns: any = [
    {
      key: 'id',
      label: '#',
      filter: false,
      sorter: false,
    },
    {
      key: 'brand',
      label: t('adminDashboard.Products.brand'),
      filter: false,
      sorter: false,
    },
    {
      key: 'article',
      label: t('adminDashboard.Products.article'),
      filter: false,
      sorter: false,
    },
    {
      key: 'description',
      label: t('adminDashboard.Products.description'),
      filter: false,
      sorter: false,
    },
    {
      key: 'current_online_price',
      label: `${t('adminDashboard.Products.online_price')} (€)`,
      filter: false,
      sorter: false,
    },
    {
      key: 'selling_price',
      label: `${t('adminDashboard.Products.selling_price')} (€)`,
      filter: false,
      sorter: false,
    },
    {
      key: 'final_points',
      label: t('adminDashboard.Products.grade_point'),
      filter: false,
      sorter: false,
    },
    {
      key: 'actions',
      label: '',
      filter: false,
      sorter: false,
    },
  ];

  const SupplierSupplyColumns: any = [
    {
      key: 'id',
      label: 'Id',
      filter: false,
      sorter: false,
      _style: baseColumnStyle,
    },
    {
      key: 'total_products',
      label: `${t('adminDashboard.Packages.total_items')}`,
      filter: false,
      sorter: false,
      _style: columnStyle,
    },
    {
      key: 'created_at',
      label: `${t('adminDashboard.CommonWords.date')}`,
      filter: false,
      sorter: false,
      _style: columnStyle,
    },

    // {
    //   key: 'total_buying_price',
    //   label: `${t('adminDashboard.Products.buying_price')} €`,
    //   filter: false,
    //   sorter: false,
    //   _style: columnStyle,
    // },
    // {
    //   key: 'total_online_price',
    //   label: `${t('adminDashboard.Products.online_price')} €`,
    //   filter: false,
    //   sorter: false,
    //   _style: columnStyle,
    // },
    {
      key: 'show_details',
      label: 'Action',
      filter: false,
      sorter: false,
      _style: columnStyle,
    },
  ];

  const SupplierSupplyProductColumns: any = [
    {
      key: 'image_list',
      label: 'Image',
      _style: baseColumnStyle,
    },
    {
      key: 'id',
      label: 'Lager Nr.',
      _style: columnStyle,

    },
    {
      key: 'supply',
      label: 'Supply',
      _style: columnStyle,

    },
    {
      key: 'article',
      label: 'Article Nr.',
      _style: columnStyle,

    },
    {
      key: 'sub_category',
      label: 'Description',
      _style: columnStyle,
    },

    {
      key: 'brand',
      label: 'Brands',
      _style: columnStyle,
    },
    {
      key: 'final_points',
      label: 'Scale',
      _style: columnStyle,
    },

    {
      key: 'created_at',
      label: 'Date',
      _style: columnStyle,
    },
    {
      key: 'action',
      label: 'Action',
      _style: columnStyle,
    },
  ];
  const PackagesColumns: any = [
    {
      key: 'id',
      label: `${t('adminDashboard.OrdersWords.package_id')} `,
      _style: baseColumnStyle,

    },
    {
      key: 'supplies',
      label: `${t('adminDashboard.SupplyManagement.supply')}`,
      _style: columnStyle,
    },
    {
      key: 'customer_company',
      label: `${t('adminDashboard.Customers.customer')}`,
      _style: columnStyle,
    },
    {
      key: 'sub_total',
      label: `${t('adminDashboard.OrdersWords.sub_total_euro')}`,
      _style: columnStyle,
    },
    {
      key: 'discount',
      label: `${t('adminDashboard.CommonWords.discount_percentage')}`,
      _style: columnStyle,
    },
    {
      key: 'total',
      label: `${t('adminDashboard.Packages.grand_total_euro')}`,
      _style: columnStyle,
    },
    {
      key: 'quantity',
      label: `${t('adminDashboard.Products.products_quantity')}`,
      _style: columnStyle,
    },
    {
      key: 'creator_first_name',
      label: `${t('adminDashboard.OrdersWords.created_by')}`,
      _style: columnStyle,
    },
    {
      key: 'note',
      label:`${t('adminDashboard.CommonWords.note')}`,
      _style: columnStyle,
    },
    {
      key: 'created_at',
      label: `${t('adminDashboard.CommonWords.date')}`,
      _style: columnStyle,
    },
    {
      key: 'status',
      label: `${t('adminDashboard.CommonWords.status')}`,
      _style: columnStyle,
    },
    {
      key: 'show_details',
      label: `${t('adminDashboard.Users.actions')}`,
      _style: columnStyle,
    },
  ];

  const AllSuppliesColumns: any = [
    {
      key: 'id',
      label: `${t('adminDashboard.SupplyManagement.supply')}`,
      _style: baseColumnStyle,
    },
    {
      key: 'is_fresh',
      label: `${t('adminDashboard.CommonWords.status')}`,
      _style: columnStyle,
    },
    {
      key: 'type',
      label: 'Type',
      _style: columnStyle,
    },
    {
      key: 'total_products',
      label: `${t('adminDashboard.Packages.total_items')}`,

      _style: columnStyle,
    },
    {
      key: 'available_products_count',
      label: `${t('adminDashboard.OrdersWords.available_items')}`,

      _style: columnStyle,
    },
    {
      key: 'show_details',
      label: `${t('adminDashboard.CommonWords.action')}`,
      _style: columnStyle,
    },
  ];

const PendingPackagesColumns = [
  {
    key: 'id',
    label: `${t('adminDashboard.OrdersWords.package_id')}`,
    _style: baseColumnStyle,
    
  },   
  {
    key: 'customer_company',
    label: `${t('adminDashboard.Customers.customer')}`,   
    _style: columnStyle,
  },
  {
    key: 'sub_total',
    label: `${t('adminDashboard.OrdersWords.sub_total_euro')}`,    
    _style: columnStyle,
  },
  {
    key: 'discount',
    label: `${t('adminDashboard.CommonWords.discount_percentage')}`,  
    _style: columnStyle,
  },
  {
    key: 'total',
    label: `${t('adminDashboard.Packages.grand_total_euro')}`,
    _style: columnStyle,
  },
  {
    key: 'quantity',
    label: `${t('adminDashboard.Products.products_quantity')}`,
    _style: columnStyle,
  },
  {
    key: 'creator_first_name',
    label: `${t('adminDashboard.OrdersWords.created_by')}`,
    _style: columnStyle,
  },
  {
    key: 'created_at',
    label: `${t('adminDashboard.CommonWords.date')}`,
    _style: columnStyle,
  },
  {
    key: 'status',
    label: `${t('adminDashboard.CommonWords.status')}`,
    _style: columnStyle,
  },
  {
    key: 'show_details',
    label: `${t('adminDashboard.Users.actions')}`,
    _style: columnStyle,
  },
];
  const AllSuppliesTypeColumns: any = [
    {
      key: 'id',
      label: 'ID',
      _style: baseColumnStyle,
    },
    {
      key: 'name',
      label: 'Name',
      _style: columnStyle,
    },
    {
      key: 'grade_A_percent',
      label: 'Grade A %',
      _style: columnStyle,
    },
    {
      key: 'grade_B_percent',
      label: 'Grade B %',
      _style: columnStyle,
    },
    {
      key: 'grade_C_percent',
      label: 'Grade C %',
      _style: columnStyle,
    },
    {
      key: 'buying_percentage',
      label: `${t('adminDashboard.Products.buying_percentage')}`,
      _style: columnStyle,
    },
    {
      key: 'supplier',
      label: `${t('adminDashboard.SupplyManagement.supplier')}`,
      _style: columnStyle,
    },

    {
      key: 'show_details',
      label: `${t('adminDashboard.CommonWords.action')}`,
      _style: columnStyle,
    },
  ];

  const AllSuppliersColumns: any = [
    {
      key: 'id',
      label: 'ID',
      _style: baseColumnStyle,
    },
    {
      key: 'name',
      label: `${t('adminDashboard.SupplyManagement.supplier')}`,
      _style: columnStyle,
    },

    {
      key: 'show_details',
      label: `${t('adminDashboard.CommonWords.action')}`,
      _style: columnStyle,
    },
  ];

  const SupplyInventoryColumns: any = [
    {
      key: 'article',
      label: `${t('adminDashboard.Products.article')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'supply',
      label: `${t('adminDashboard.SupplyManagement.supply')}`,

      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'product',
      label: `${t('adminDashboard.Products.product_id')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'usage',
      label: `${t('adminDashboard.Products.usage')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'final_grade',
      label: `${t('adminDashboard.Products.final_grade')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'broken_glass',
      label: `${t('adminDashboard.Products.broken_glass')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'price_percent',
      label: `${t('adminDashboard.Products.price_percent')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'scale',
      label: `${t('adminDashboard.Products.scale')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'offer_price',
      label: 'Offer price',
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
    {
      key: 'buying_price',
      label: `${t('adminDashboard.Products.buying_price')}`,
      _style: { width: '10%', borderColor: 'white', borderWidth: 3, fontSize: FONT_18, textAlign: 'center' },
    },
  ];

  const PendingCustomersColumns: any = [
    {
      key: 'email',
      label: `${t('adminDashboard.CommonWords.email')}`,
      _style: baseColumnStyle,
    },
    {
      key: 'first_name',
      label: `${t('adminDashboard.CommonWords.first_name')}`,
      _style: columnStyle,
    },
    {
      key: 'last_name',
      label: `${t('adminDashboard.CommonWords.last_name')}`,
      _style: columnStyle,
    },
    {
      key: 'jtl_id',
      label: `${t('adminDashboard.CommonWords.jtl_id')}`,
      _style: columnStyle,
    },
    {
      key: 'company',
      label: `${t('adminDashboard.CommonWords.company')}`,
      _style: columnStyle,
    },
    {
      key: 'phone_number',
      label: `${t('adminDashboard.Customers.phone_number')}`,
      _style: columnStyle,
    },
    {
      key: 'date_joined',
      label: `${t('adminDashboard.CommonWords.date')}`,
      _style: columnStyle,
    },
    {
      key: 'show_details',
      label: `${t('adminDashboard.Users.actions')}`,
      _style: columnStyle,
    },
  ];

  const OrdersColumns: any = [
    {
      key: 'id',
      label: `${t('adminDashboard.OrdersWords.order_id')}`,
      _style: baseColumnStyle,
    },
    {
      key: 'package_id',
      label: `${t('adminDashboard.OrdersWords.package_id')}`,
      _style: columnStyle,
    },
    {
      key: 'package_customer_company',
      label: `${t('adminDashboard.Customers.customer')}`,
      _style: columnStyle,
    },
    {
      key: 'package_sub_total',
      label: `${t('adminDashboard.OrdersWords.sub_total_euro')}`,
      _style: columnStyle,
    },
    {
      key: 'package_discount',
      label: `${t('adminDashboard.CommonWords.discount_percentage')}`,
      _style: columnStyle,
    },
    {
      key: 'package_total',
      label: `${t('adminDashboard.Packages.grand_total_euro')}`,
      _style: columnStyle,
    },
    {
      key: 'package_quantity',
      label: `${t('adminDashboard.Products.products_quantity')}`,
      _style: columnStyle,
    },
    {
      key: 'package_creator_first_name',
      label: `${t('adminDashboard.OrdersWords.created_by')}`,
      _style: columnStyle,
    },
    {
      key: 'truck_capacity',
      label: `${t('adminDashboard.CatalogManagement.loading_meters')}`,
      _style: columnStyle,
    },
    {
      key: 'items_selected',
      label: `${t('adminDashboard.Products.items_selected_percentage')}`,
      _style: columnStyle,
    },
    {
      key: 'package_status',
      label: `${t('adminDashboard.CommonWords.status')}`,
      _style: columnStyle,
    },
    {
      key: 'show_details',
      label: `${t('adminDashboard.Users.actions')}`,
      _style: columnStyle,
      filter: false,
      sorter:false
    },
  ];

  const ProductColumns: any = [
    {
      key: 'image_list',
      label: 'Image',
      filter: false,
      _style: baseColumnStyle,
    },
    {
      key: 'id',
      label: 'Lager Nr.',
      _style: columnStyle,
    },
    {
      key: 'supply',
      label: `${t('adminDashboard.SupplyManagement.supply')}`,
      _style: columnStyle,
    },
    {
      key: 'article',
      label: `${t('adminDashboard.Products.article')}`,
      _style: columnStyle,
    },
    {
      key: 'sub_category',
      label: 'Group',
      _style: columnStyle,
    },
    {
      key: 'current_online_price',
      label: `${t('adminDashboard.Products.online_price')}`,
      _style: columnStyle,
    },
    {
      key: 'brand',
      label: `${t('adminDashboard.Products.brands')}`,
      _style: columnStyle,
    },
    {
      key: 'final_points',
      label: `${t('adminDashboard.Products.grade_point')}`,
      _style: columnStyle,
    },
    {
      key: 'is_sold',
      label: `${t('adminDashboard.CommonWords.status')}`,
      _style: columnStyle,
    },
    {
      key: 'uploading_user_name',
      label: 'Scanned By',
      _style: columnStyle,
    },
    {
      key: 'created_at',
      label: `${t('adminDashboard.CommonWords.date')}`,
      _style: columnStyle,
    },
    {
      key: 'action',
      label: `${t('adminDashboard.CommonWords.action')}`,
      _style: columnStyle,
    },
  ];

  const CatalogColumns: any = [
    {
      key: 'article_number',
      label: `${t('adminDashboard.Products.article')}`,
      _style: baseColumnStyle,
    },
    {
      key: 'supplier_name',
      label: `${t('adminDashboard.SupplyManagement.supplier')}`,
      _style: columnStyle,
    },
    {
      key: 'brand_name',
      label: `${t('adminDashboard.Products.brand')}`,
      _style: columnStyle,
    },
    {
      key: 'sub_cat_name',
      label: `${t('adminDashboard.OrdersWords.sub_category')}`,
      _style: columnStyle,
    },
    {
      key: 'category',
      label: `${t('adminDashboard.Products.category')}`,
      _style: columnStyle,
    },
    {
      key: 'model',
      label: `${t('adminDashboard.CommonWords.model')}`,
      _style: columnStyle,
    },
    {
      key: 'retail_price',
      label: `${t('adminDashboard.Products.retail_price')}`,
      _style: columnStyle,
    },
    {
      key: 'online_price',
      label: `${t('adminDashboard.Products.online_price')}`,
      _style: columnStyle,
    },
    {
      key: 'buying_price',
      label: `${t('adminDashboard.CommonWords.buying_price')}`,
      _style: columnStyle,
    },
    {
      key: 'weight_kg',
      label: `${t('adminDashboard.CatalogManagement.weight_kg')}`,
      _style: columnStyle,
    },
    {
      key: 'action',
      label: `${t('adminDashboard.Users.actions')}`,
      _style: columnStyle,
    },
  ];

  const CustomerColumns: any  = [
    {
      key: 'email',
      label: `${t('adminDashboard.CommonWords.email')}`,
      _style: baseColumnStyle,
    },
    {
      key: 'first_name',
      label: `${t('adminDashboard.CommonWords.first_name')}`,
      _style: columnStyle,
    },
    {
      key: 'last_name',
      label: `${t('adminDashboard.CommonWords.last_name')}`,
      _style: columnStyle,
    },
    {
      key: 'jtl_id',
      label: `${t('adminDashboard.CommonWords.jtl_id')}`,
      _style: columnStyle,
    },
    {
      key: 'company',
      label: `${t('adminDashboard.CommonWords.company')}`,
      _style: columnStyle,
    },
    {
      key: 'vat',
      label: `VAT`,
      _style: columnStyle,
    },
    {
      key: 'is_active',
      label: 'Status',
      _style: columnStyle,
    },
    {
      key: 'phone_number',
      label: `${t('adminDashboard.Customers.phone_number')}`,
      _style: columnStyle,
    },
    {
      key: 'date_joined',
      label: `${t('adminDashboard.CommonWords.joined_date')}`,
      _style: columnStyle,
    },
    {
      key: 'action',
      label: '',
      _style: columnStyle,
    
    },
  ];

  const getLink = (model: string): string => {
    return `www.idealo.de/preisvergleich/MainSearchProductCategory.html?q=${model}`;
  }

  const returnDateFormat = (dateString: string) => {
    // Create a new Date object from the ISO 8601 string
    const date = new Date(dateString);

    // Get the day of the month
    const day = date.getDate();

    // Get the month as a short name (e.g., "Nov")
    const month = date.toLocaleString('en-US', { month: 'short' });

    // Get the full year
    const year = date.getFullYear();

    // Get the hours (12-hour format) and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM or PM suffix
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${day} ${month}, ${year} ${hours}:${minutes} ${ampm}`;
  }
  const getCatalogsByModelsData = (model: string) => {
    const encodedModel = encodeURIComponent(model);
    filterCatalogByModel(encodedModel).then((response) => {
      let updatedResponseData: CatalogItem[] = [];
      response.data.map((responseItem: any) => {
        updatedResponseData.push({
          ...responseItem,
          brand_name: responseItem.brand.name,
          sub_cat_name: responseItem.sub_category.name,
          supplier_name: responseItem.supplier.name,
        })
      })
      dispatch(setCatalogArticles(updatedResponseData))
    }).catch((e) => {
      console.log('Error while getting catalog by model: ', e)
    });
  }

  const getCatalogData = (article_number: string) => {
    const encodedModel = encodeURIComponent(article_number);
    getCatalogById(encodedModel).then((response) => {
      let updatedResponseData = {
        ...response.data,
        brand_name: response.data.brand.name,
        sub_cat_name: response.data.sub_category.name,
        supplier_name: response.data.supplier.name,
      }
      dispatch(setCatalogArticles([updatedResponseData]))
    }).catch((e: any) => {
      console.log('Error while getting catalog by id: ', e)
    });
  }
  const getInCompleteCatalog = () => {
    filterCatalogByModel("NOT IN OTTO API").then((response) => {
      if (response?.data?.length > 0) {
        dispatch(setInCompleteCatalog(true));
      }
      else {
        dispatch(setInCompleteCatalog(false));
      }


    }).catch((e) => {
      console.log('Error while getting catalog by model: ', e)
    });
  }
  const getPackagesData = async () => {
    getPackages().then((response) => {
      dispatch(setPackages(response?.data?.results));
    }).catch((e) => {
      dispatch(setPackages([]));
      console.log('error getting packages: ', e);
    });
  }
  const getPendingPackagesData = async() => {
    getPendingPackages().then((response) => {
      dispatch(setPendingPackages(response?.data));
    }).catch((e) => {
      dispatch(setPendingPackages([]));
      console.log('error getting packages: ', e);
    });
}

  const getOrdersData = () => {
    getOrders().then((response) => {
      dispatch(setOrders(response?.data?.results));
    }).catch((e) => {
      dispatch(setOrders([]));
      console.log('error getting orders: ', e);
    });
  }

  const getCustomersData = () => {
    getCustomers().then((response) => {
      let updatedCustomersArray: CustomerDetails[] = [];
      for (let responseItem of response.data) {
        updatedCustomersArray.push({
          ...responseItem,
          id: responseItem.id,
          email: responseItem.user.email,
          first_name: responseItem.user.first_name,
          last_name: responseItem.user.last_name,
          phone_number: responseItem.user.phone_number,
          is_active: responseItem.user.is_active,
          date_joined: responseItem.user.date_joined
        });
      }
      dispatch(setCustomers(updatedCustomersArray));
    }).catch((e) => {
      dispatch(setCustomers([]));
      console.log('get customers error: ', e);
    });
  }

  const getUsageData = () => {
    getUsage().then((response) => {
      dispatch(setUsage(response.data.results))
    }).catch((e) => {
      dispatch(setUsage([]))
    })
  }

  const downloadZipImages = (product_ids: number[]) => {
    let form_data = {
      product_ids: [...product_ids],
    }
    downloadImages(form_data).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = 'images.zip';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }).catch((e) => {
      console.log('error downloading images: ', e);
    })
  }

  const logout = () => {

    // clear warehouse slice.
    dispatch(setWarehouseSetArticles([]));
    dispatch(setWarehouseSelectedProducts([]));
    dispatch(setWarehouseSelectedSetArticles([]));
    dispatch(setWarehouseCart({
      standalone: [],
      unpaired: [],
      paired: [],
      pallets: [],
    }));
    dispatch(setWarehousePackageProducts([]));
    dispatch(setWarehouseSelectedPallets([]));
    dispatch(setWarehousePallets([]));
    dispatch(setWarehousePackageTotalPrice(0));
    dispatch(setWarehousePackageDiscount(0));
    // dispatch(setCountries([]));

    logoutUser().then((logoutResponse) => {
      getCountries(); // Countries are irrespective of the authentication so there is no need to reset them.
      
      dispatch(setPackages({ results: [], count: 0 }));
      dispatch(setOrders({ results: [], count: 0 }));
      dispatch(setSelectedSetArticles([]));
      dispatch(setSelectedProducts([]));
      dispatch(setSelectedPallets([]));
      dispatch(setSetArticles([]));
      dispatch(setPackageProducts([]));
      dispatch(setCart({
        standalone: [],
        unpaired: [],
        paired: [],
        pallets: [],
      }));
      dispatch(setProducts([]));
      dispatch(setProductsCount(0));
      dispatch(setActivePalletsCount(0));
      dispatch(setActiveProductsCount(0));
      setSuppliers([]);
      setSupplyTypes([]);
      setProductSupplies([]);
      setProductBrands([]);
      setProductArticles([]);
      setProductCategories([]);
      setProductSubCategories([]);
      dispatch(setCustomers([]));
      dispatch(setSelectedTab(0));
      dispatch(setCatalogArticles([]));
      dispatch(setLoggedinUser({}));
      dispatch(setIsAuthenticated(false));

    }).catch((e) => {
      getCountries(); // Countries are irrespective of the authentication so there is no need to reset them.

      
      dispatch(setSelectedTab(0));
      // clear warehouse slice.
      dispatch(setWarehouseSetArticles([]));
      dispatch(setWarehouseSelectedProducts([]));
      dispatch(setWarehouseSelectedSetArticles([]));
      dispatch(setWarehouseCart({
        standalone: [],
        unpaired: [],
        paired: [],
        pallets: [],
      }));
      dispatch(setWarehousePackageProducts([]));
      dispatch(setWarehouseSelectedPallets([]));
      dispatch(setWarehousePallets([]));
      dispatch(setWarehousePackageTotalPrice(0));
      dispatch(setWarehousePackageDiscount(0));
      // auth slice 
      dispatch(setLoggedinUser({}));
      dispatch(setIsAuthenticated(false));

    });
  }
  const getAllPalletsData = () => {
    filterPallets().then((response) => {
      dispatch(setPalletProducts(response.data));
    }).catch((e) => {
      dispatch(setPalletProducts([]));
      console.log('get pallets data error: ', e);
    });
  }

  const getAvailablePalletsCount = () => {
    getActivePalletsCount().then((response) => {
      dispatch(setActivePalletsCount(response.data.active_pallets_count));
    }).catch((e) => {
      dispatch(setActivePalletsCount(0));
      console.log('get pallets count error: ', e);
    });
  }
  const getAllPackagesCount = () => {
    getPackagesCount().then((response) => {
      dispatch(setAllPackagesCount(response.data.all_packages_count));
    }).catch((e) => {
      dispatch(setAllPackagesCount(0));
      console.log('get packages count error: ', e);
    });
  }
  const getAllOrdersCount = () => {
    getOrdersCount().then((response) => {
      dispatch(setAllOrdersCount(response.data.all_orders_count));
    }).catch((e) => {
      dispatch(setAllOrdersCount(0));
      console.log('get orders count error: ', e);
    });
  }

  const getAllProductSupplies = async () => {
    getProductSupplies().then((response) => {
      dispatch(setProductSupplies(response.data));
    }).catch((e) => {
      dispatch(setProductSupplies([]));
    });
  }

  const getAllProductArticles = () => {
    getProductArticles().then((response) => {
      dispatch(setProductArticles(response.data));
    }).catch((e) => {
      dispatch(setProductArticles([]));
    });
  }

  const getManualPrices = () => {
    getManualProductPrices().then((response) => {
      dispatch(setManualProductPrices(response.data));
    }).catch((e) => {
      dispatch(setManualProductPrices([]));
    })
  }

  const getAllProductSuppliers = () => {
    getSuppliers().then((response) => {
      dispatch(setSuppliers(response.data));
    }).catch((e) => {
      dispatch(setSuppliers([]));
    });
  }

  const getAllProductSupplyTypes = () => {
    getSupplyTypes().then((response) => {
      dispatch(setSupplyTypes(response.data));
    }).catch((e) => {
      dispatch(setSupplyTypes([]));
    });
  }

  const getAllProductBrands = () => {
    getProductBrands().then((response) => {
      dispatch(setProductBrands(response.data));
    }).catch((e) => {
      dispatch(setProductBrands([]));
    });
  }

  const getAllProductCategoryQuestions = () => {
    getProductsCategoryQuestions().then((response) => {
      dispatch(setProductCategoryQuestions(response.data));
    }).catch((e) => {
      dispatch(setProductCategoryQuestions([]));
    });
  }

  const getAllProductCategories = () => {
    getProductCategories().then((response) => {
      dispatch(setProductCategories(response.data));
    }).catch((e) => {
      dispatch(setProductCategories([]));
    });
  }

  const getAllProductSubCategories = () => {
    getProductSubCategories().then((response) => {
      dispatch(setProductSubCategories(response.data));
    }).catch((e) => {
      dispatch(setProductSubCategories([]));
    });
  }

  const getAllGroupsData = () => {
    getAllGroups().then((response) => {
      dispatch(setPermissionGroups(response.data));
    }).catch((e) => {
      dispatch(setPermissionGroups([]));
    });
  }

  const getAllPermissionsData = () => {
    getAllPermissions().then((response) => {
      dispatch(setPermissions(response.data));
    }).catch((e) => {
      dispatch(setPermissions([]));
    });
  }

  const getAvailableProductsCount = () => {
    getActiveProductsCount().then((response) => {
      dispatch(setActiveProductsCount(response.data.active_products_count));
    }).catch((e) => {
      dispatch(setActiveProductsCount(0));
      console.log('get pallets count error: ', e);
    });
  }

  const getAllProductsData = async (page: number = PAGE_NUMBER, accumulatedProducts: ProductItem[] = []) => {
    try {
      const response = await getProducts(page);
      const newProducts = [...accumulatedProducts, ...response.data.results];
      dispatch(setProducts(newProducts));
      dispatch(setProductsCount(response.data.count));

      if (response.data.next) {
        // await loadMoreProducts(response.data.next, newProducts);
      }
    } catch (e) {
      console.log('get products error: ', e);
    }
  };

  const filterByPriceCriteria = (item: any, minScale: any, maxScale: any) => {
    const meetsMinScaleCriteria = minScale === '' || item.final_points >= parseInt(minScale);
    const meetsMaxScaleCriteria = maxScale === '' || item.final_points <= parseInt(maxScale);
    return meetsMinScaleCriteria && meetsMaxScaleCriteria;
  };

  const filterByScaleCriteria = (item: any, minPrice: any, maxPrice: any) => {
    const meetsMinPriceCriteria = minPrice === '' || item.selling_price >= parseFloat(minPrice);
    const meetsMaxPriceCriteria = maxPrice === '' || item.selling_price <= parseFloat(maxPrice);
    return meetsMinPriceCriteria && meetsMaxPriceCriteria;
  };

  const filterBySubCategory = (item: any, selectedSubCategory: any) => {
    const meetsCategoryCriteria = selectedSubCategory.length === 0 || selectedSubCategory.includes(item.sub_category);
    return meetsCategoryCriteria;
  };

  const searchItem = (item: any, searchedInput: string) => {
    for (let key in item) {
      if (typeof item[key] === 'string' && item[key].toLowerCase().includes(searchedInput.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  const filterBySearchedCriteria = (item: any, searchedInput: string) => {
    return searchItem(item, searchedInput);
  };

  // Download PDF
  const downloadPDF = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = decodeURIComponent(fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Function to call respective PDF endpoint based on endpoint type
  const callPDFEndPoint = async (id: number | string, endpoint: string) => {
    switch (endpoint) {
      case 'orderPDF':
        return await getOrderPDFURL(id);
      case 'supplyPDF':
        return await getSupplyPDFURL(id);
      case 'productPDF':
        return await getProductPDFURL(id);
      default:
        throw new Error('Invalid endpoint');
    }
  };

  // Check PDF status, if its ready invoke download functions
  const checkPDFStatus = async (id: number | string, endpoint: string) => {
    try {
      const response = await callPDFEndPoint(id, endpoint);
      if (response.data.url) {
        const url = response.data.url;
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        downloadPDF(url, fileName);
      } else {
        //Update UI with current status
        setToastShown(prevToastShown => {
          if (!prevToastShown) {
            addToast(PDFGeneratedSuccessToast(response?.data?.message, () => { addToast(null) }));
            return true;
          }
          return prevToastShown;
        });
        // PDF is still being generated, wait for a while and check again
        setTimeout(() => {
          checkPDFStatus(id, endpoint);
        }, 5000); // Poll every 5 seconds (adjust as needed)
      }
    } catch (error: any) {
      addToast(PDFGeneratedErrorToast(error, () => { addToast(null) }));
    }
  };

  // change password function

  const changePassword = async (newPassword1: string, newPassword2: string) => {
    changeAuthPassword({ 'new_password1': newPassword1, 'new_password2': newPassword2 }).then((response) => {
      alert('password changed login again.');
      logout();
    }).catch((e) => {
      alert(e?.response?.data?.new_password2)
    })
  }

  const getCountries = async ()  => {
    getAllCountries().then((response) => {
      // console.log(response.data);
      dispatch(setCountries(response.data));
    })
  }

 
  return {
    getPackagesData, getOrdersData, getCustomersData, getUsageData, downloadZipImages, getAllProductSupplyTypes, PendingPackagesColumns,
    logout, getAllProductSupplies, getAllProductArticles, getManualPrices, getAllProductSuppliers, getAllProductBrands, PendingCustomersColumns,
    getAllProductCategories, getAllProductSubCategories, getAllAnalyticsData, getAllGroupsData, getAllPermissionsData, OrdersColumns,
    getAllProductsData, filterByScaleCriteria, filterBySearchedCriteria, filterBySubCategory, checkPDFStatus, toast, getAllPalletsData,
    getAllProductCategoryQuestions, changePassword, getAvailablePalletsCount, getAvailableProductsCount, filterByPriceCriteria, ProductColumns,
    getCatalogData, getCatalogsByModelsData, getAllOrdersCount, getAllPackagesCount, PalletProductReducedColumns, customerOrderColumns,
    customerOrderDetailsColumns, SupplierSupplyColumns, returnDateFormat, SupplierSupplyProductColumns, getInCompleteCatalog, PackagesColumns,
    getLink, AllSuppliesColumns, AllSuppliesTypeColumns, AllSuppliersColumns, SupplyInventoryColumns, CatalogColumns, getPendingPackagesData,
    getCountries, CustomerColumns
  };
};