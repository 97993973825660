import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CModalContent, CSpinner, CRow, CCol, CTooltip } from "@coreui/react";
import { useState, useEffect } from "react";
import { t } from "i18next";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmOrder?: () => void;
    confirmButtonText: string;
    packageStatus: string;
}
const ConfirmationModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, confirmOrder, packageStatus} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(packageStatus === 'F');
    
    useEffect(() => {
        setIsButtonDisabled(packageStatus === 'F')
    }, [packageStatus]);
    
    // Pulling code outside to show tooltip message and for code reusability
    const confirmOrderButton = <CButton
        color="success"
        onClick={() => {setLoading(true); confirmOrder && confirmOrder() }}
        disabled={isButtonDisabled}
        >
        Accept order
    </CButton>

    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal}>
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{loading ? <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> : bodyText}</div>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={() => {setLoading(true); onClickConfirmation()}}>{confirmButtonText}</CButton>
                {
                    isButtonDisabled ? (
                        <CTooltip content={t('adminDashboard.Packages.already_ordered_edit_package_placed_order_message')} placement='top'>
                        <div style={{ display: 'inline-block' }}>
                            {confirmOrderButton}
                        </div>
                    </CTooltip>
                    ) : (
                        confirmOrderButton
                    )
                }
                <CButton color="danger" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default ConfirmationModal;